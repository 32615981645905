import Swipe from 'swipejs';

(function () {
    let player;
    const setImages = (imgId) => {
        const imgEle = document.getElementById(imgId)
        const appImage = document.querySelector('#app-container img')
        const appSrc = appImage.src
        const appId = appImage.id

        appImage.src = imgEle.src
        appImage.id = imgEle.id

        imgEle.src = appSrc
        imgEle.id = appId

    }

    const mq = window.matchMedia("(max-width: 799px)")


    const loadYoutubeScript = () => {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    const activateAppImages = () => {
        const appPreviews = document.querySelectorAll('[data-preview]');
        appPreviews.forEach(
            (ap) => {
                ap.addEventListener("click", (e) => {
                    appPreviews.forEach(ap => {
                        ap.classList.remove('active')
                        const icon = ap.querySelector('img')
                        icon.src = icon.src = icon.getAttribute('data-icon') + '-var2.svg'
                    })
                    e.currentTarget.classList.add('active')
                    const icon = e.currentTarget.querySelector('img')
                    icon.src = icon.getAttribute('data-icon') + '-var1.svg'

                    setImages(e.currentTarget.getAttribute('data-img'))
                });
            }
        )
    }

    const activateSwipe = () => {
        window.mySwipe = new Swipe(document.getElementById('slider'), {
            callback: function (index, elem, dir) {
                const dots = document.querySelectorAll('.dot');

                dots.forEach(dot => dot.classList.remove('active'))
                dots[index].classList.add('active')
            },
        });

    }

    const playVideo = (video) => {
        let iframe = document.querySelector('#player')
        video.playVideo();

        var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
        if (requestFullScreen && mq.matches) {
            requestFullScreen.bind(iframe)();
        }
    }

    const onPlayerReady = (event) => {
        playVideo(event.target)
    }

    const setModal = () => {
        const treatmentBtns = document.querySelectorAll('.find-treatment');
        const showMovieBtn = document.getElementById('show-movie');
        const modal = document.getElementById('modal-container');
        const ytModal = document.getElementById('yt-modal-container');
        const closeBtns = document.querySelectorAll(".modal-close");

        if (showMovieBtn) {
            showMovieBtn.addEventListener('click', () => {

                ytModal.classList.add('open')


                if (!player) {
                    player = new YT.Player('player', {
                        videoId: 'KemM8zh03e4',
                        events: {
                            'onReady': onPlayerReady
                        }
                    });

                } else {
                    playVideo(player)
                }

            })
        }

        treatmentBtns.forEach(btn => {
            btn.addEventListener('click', (e) => {
                modal.classList.add('open')
                if(event.target.id === 'client') {
                    ga('send', 'event', 'button', 'click', 'Find treatment');
                } else {
                    ga('send', 'event', 'button', 'click', 'Connect company');
                }
            })
        })

        closeBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                modal.classList.remove('open')
                if (ytModal) {
                    ytModal.classList.remove('open')
                    player && player.stopVideo();
                }

            })
        })

        window.onclick = (event) => {
            if (event.target == modal || event.target == ytModal) {
                modal.classList.remove('open');
                if (ytModal) {
                    ytModal.classList.remove('open')
                    player && player.stopVideo();
                }
            }
        }
    }

    const disableGuideBoxesMobile = () => {
        if (mq.matches) {
            const iconBoxes = document.querySelectorAll('.guide-box-icon')

            iconBoxes.forEach(iconBox => {
                iconBox.style.pointerEvents = "none";
                iconBox.classList.remove('active')
                const icon = iconBox.querySelector('img')
                icon.src = icon.src = icon.getAttribute('data-icon') + '-var2.svg'
            })

        }
    }

    const setFooter = () => document.getElementById("footer-uboddy").innerHTML = `uboddy.com - © ${new Date().getFullYear()}`



    activateAppImages();
    setModal();
    activateSwipe();
    disableGuideBoxesMobile();
    setFooter();
    loadYoutubeScript();

})()

